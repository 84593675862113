import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { Nav } from 'components/nav'
import { MeetUsMenu } from 'components/pages/MeetUsPage'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const NewsPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO title="Meet Us - Feed" pageUrl="/meet-us/feed/" description="Information about Tumba around the Web and in real life." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 py-6">
        <HeaderLink className="text-primary-2" />
        <Link to="/meet-us/" className="block pt-12 lg:py-12 text-left">
          <h1 className="pt-4 pb-4 text-4xl lg:text-5xl text-shadow">Meet us</h1>
        </Link>
      </Header>
      <Section className="z-20" gridRow={2}>
        <div className="grid grid-cols-22ch-auto row-gap-6" style={{ gridTemplateRows: 'min-content 1fr' }}>
          <div className="hidden lg:block lg:row-start-1 text-7xl lg:text-8xl text-right">&nbsp;</div>
          <MeetUsMenu className="col-span-2 lg:col-span-1 row-start-1 lg:row-start-2" />
          <div className="col-span-2 lg:row-span-2 lg:row-start-1 text-right text-primary-2">
            {/* 2024 */}
            <div>
              <a href="https://en.wemakefuture.it/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">WMF - We Make Future</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">WMF, Bologna</p>
            </div>
            <div>
              <a href="https://tuesfest.bg/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">TUES Fest 2024</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">TUES</p>
            </div>
            <div>
              <a href="https://hacktues.bg/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Hack TUES 2024</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">TUES</p>
            </div>
            <div>
              <Link to="/blog/nextgen-eu-sw">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Публична покана</p>
              </Link>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">проект “Технологична модернизация в предприятието”</p>
            </div>
            {/* 2023 */}
            <div>
              <a href="https://hackathon.gate-ai.eu/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">AEC Hackathon, Sofia</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">GATE Institute</p>
            </div>
            <div>
              {/*<a href="https://websummit.com/" target="_blank" rel="noreferrer">*/}
              <a href="https://websummit.com/startups/featured-startups?q=eyJxdWVyeSI6IldlUmlkZS5Ub2RheSIsInBhZ2UiOjEsImNvbmZpZ3VyZSI6eyJoaXRzUGVyUGFnZSI6NDgsInRhZ0ZpbHRlcnMiOlsid3MyMyJdfX0%3D" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Web Summit</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Web Summit 2023, Lisbon</p>
            </div>
            <div>
              {/*https://www.youtube.com/watch?v=ERnxsgRGYbM&t=3332s*/}
              <a href="https://www.facebook.com/events/228294829894832/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">WeRide.Today Launch Event</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">ZaZemiata.org, Toplocentrala</p>
            </div>
            <div>
              <Link to="/blog/nextgen-eu-hw">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Публична покана</p>
              </Link>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">проект “Технологична модернизация в предприятието”</p>
            </div>
            <div>
              <a href="https://greentransition.bg/speaker/emilian-abadjiev/?lang=en" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Green Transition</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Green Transition 2023, Sofia</p>
            </div>
            <div>
              {/*https://www.eventbrite.be/e/spread2inno-local-event-bulgaria-tickets-622379372457*/}
              <a href="https://www.spread2inno.eu/startup-event-3-cleantech-bulgaria-event/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">SPREAD2INNO Bulgaria</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Cleantech Bulgaria, Sofia Tech Park</p>
            </div>
            <div>
              <a href="https://velo-city2023.com/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Leading The Transition</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Velo-city 2023, Leipzig</p>
            </div>
            <div>
              <a href="https://dni.career.fmi.uni-sofia.bg/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Career forum</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Faculty of Mathematics and Informatics</p>
            </div>
            <div>
              <a href="https://tuesfest.bg/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">TUES Fest 2023</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">TUES</p>
            </div>
            <div>
              <Link to="/blog/nextgen-eu">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">ТУМБА СЪЛЮШЪНС</p>
              </Link>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">проект “Технологична модернизация в предприятието”</p>
            </div>
            <div>
              <a href="https://thehub-aubg.com/hackaubg" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">HackAUBG 5.0</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">The HUB AUBG</p>
            </div>
            {/* 2022 */}
            <div>
              <a href="https://www.smartcityexpo.com/2022-highlights/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Smart City Expo World Congress</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">SCEWC22, Barcelona</p>
            </div>
            <div>
              <a href="https://www.velo-city2022.com/en/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Cycling the Change</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Velo-city 2022, Ljubljana</p>
            </div>
            <div>
              <a href="https://www.facebook.com/events/701381827751711/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Internship forum</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Technical University of Sofia</p>
            </div>
            {/* 2021 */}
            <div>
              <a href="https://devstyler.bg/blog/2021/11/09/dva-dni-do-sabitieto-zhenite-lideri-v-tech-industriyata/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#WomenInTech</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">DevStyleR</p>
            </div>
            <div>
              <a href="https://devstyler.bg/blog/2021/09/27/uebinar-pozitsiyata-java-developer-chast-2/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Webinar: Position Java Developer - part 2</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">DevStyleR</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/280897034/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SwiftSofia: What’s new in iOS 15</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
            <div>
              <a href="https://devstyler.bg/blog/2021/07/26/pozitsiyata-java-razrabotchik-zaplashtane-pridobivki-karierno-razvitie-obuchenie/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Webinar: Position Java Developer</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">DevStyleR</p>
            </div>
            <div>
              {/*<a href="https://events.bizzabo.com/307685/agenda/session/581028" target="_blank" rel="noreferrer">*/}
              {/*<a href="https://events.youngstartup.com/agenda/agenda-vcglobal21/" target="_blank" rel="noreferrer">*/}
              <a href="https://events.youngstartup.com/events/vcglobal21/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Startups need more than money to succeed: How to create software that scales?</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Venture Summit Virtual Connect | Global 21</p>
            </div>
            <div>
              <a href="https://innoair-sofia.eu/bg/%D1%85%D0%B0%D0%BA%D0%B0%D1%82%D0%BE%D0%BD%D0%B8/96-%D1%85%D0%B0%D0%BA%D0%B0%D1%82%D0%BE%D0%BD%D0%B8/232-innoairchallenge-making-urban-mobility-smarter.html" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">InnoAirChallenge: Making urban mobility smarter</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">InnoAir Sofia</p>
            </div>
            {/* 2020 */}
            <div>
              <a href="https://devstyler.bg/blog/2020/06/05/spodari-sportuvaj-daryavaj-spasyavaj-zhivot/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SpoDari how to save a life</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">DevStyleR</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/268801935/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SwiftSofia: What’s new in Swift 5.2 & the road ahead to Swift 6.0</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
            {/* 2019 */}
            <div>
              <a href="https://www.spodari.org/events/play2019" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SpoDari PLAY</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SpoDari</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/262671966/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SwiftSofia 3rd Birthday - SwiftUI</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
            <div>
              <a href="https://www.youngdriver.bg/2019/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Best young driver of Bulgaria 2019</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">youngdriver.bg</p>
            </div>
            <div>
              <a href="https://www.spodari.org/events/spodaridrive-1904" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SpoDari DRIVE</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SpoDari</p>
            </div>
            <div>
              <a href="https://devstyler.bg/blog/2019/03/29/istoriyata-na-naj-umnoto-kolelo-wattbike/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Building the smartest indoor bike</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">DevStyleR</p>
            </div>
            <div>
              <a href="https://today.aubg.edu/news/hackaubg-2-0-digital-wellbeing/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">HackAUBG 2.0</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">The HUB AUBG</p>
            </div>
            {/* 2018 */}
            <div>
              <a href="https://2018.aismart.tech/smart-sofia-hackathon/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Smart Sofia Hackathon</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">AI & Smart_Tech 2018</p>
            </div>
            <div>
              <a href="https://devstyler.bg/blog/2018/09/27/ravnopravieto-v-ofisa-na-tumba-solutions/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Empowerment at Tumba</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">DevStyleR</p>
            </div>
            <div>
              <a href="https://www.webit.org/festival-europe/previous.php" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Webit.Festival Europe 2018</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Webit.Festival Europe</p>
            </div>
            <div>
              <a href="https://www.youngdriver.bg/2018/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Best young driver of Bulgaria 2018</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">youngdriver.bg</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/248499717/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SwiftSofia - CI-CD for iOS devs</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
            {/* 2017 */}
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/244289749/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SwiftSofia - Debugging Love</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
            <div>
              <a href="https://devstyler.bg/blog/2017/07/18/razrabotka-na-tumba-solutions-specheli-prestizhna-m/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Digital publishing award</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">DevStyleR</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Docker-Bulgaria/events/239128556/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Infrastructure as code</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Docker-Bulgaria</p>
            </div>
            <div>
              <a href="https://2017.spaceappschallenge.org/locations/sofia/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Space Apps 2017 Sofia</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">NASA Space Apps Challenge</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/238289451/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SwiftSofia - 007</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
            {/* 2016 */}
            <div>
              {/*<a href="http://2016.java2days.com/speaker/bobi-tanev/" target="_blank" rel="noreferrer">*/}
              {/*<a href="http://2016.java2days.com/agenda/" target="_blank" rel="noreferrer">*/}
              <a href="http://2016.java2days.com/category/sponsors/#post-1731" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Things we don’t do, but we should when starting an Android project</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">Java2Days 2016</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/234641878/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">#SwiftSofia - November Meetup</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
            <div>
              <a href="https://careerdays.bg/bg/it-careerdays/arhive-2016" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">IT Careerdays</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">careerdays.bg</p>
            </div>
            <div>
              <a href="https://www.meetup.com/Swift-Meetup-Sofia/events/232049188/" target="_blank" rel="noreferrer">
                <p className="pr-24 text-2xl lg:text-3xl font-normal tracking-normal">Swift Meetup Sofia: The Kick Off</p>
              </a>
              <p className="text-lg lg:text-xl text-primary-3 text-shadow">#SwiftSofia</p>
            </div>
          </div>
        </div>
      </Section>
      <BackgroundWithGrid image="meet-us-news" gridRow="1/4" />
      <div className="z-0 bg-primary-1" style={{ gridColumn: '1 / -1', gridRow: '1 / 4', opacity: 0.85 }} />
      <div className="z-0 h-16" style={{ gridRow: 3, gridColumn: '1/-1', background: 'linear-gradient(#00edae00, #00edae)' }} />
      <Nav className="z-20 text-primary-2" gridRow={4} />
    </MainWithMenu>
  </Layout>
)

export default NewsPage
